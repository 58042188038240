import { Box } from "grommet";
import React from "react";
import { FormFieldInput } from "../elements/form_field";
import StandardText, { COLOR } from "../elements/standard_text";
import { weekdays } from "../catalog/utils";
import MenuTime from "./menu_time";
import { Upload } from "grommet-icons";
import PrimaryButton from "../elements/primary_button";

const PartnerInfo = ({ partner, onPartnerChange, onAccountChange, pushOpening }) => {
    return (

        <Box width={'full'} gap='medium' style={{ minHeight: 'auto' }}>
            <Box width={'full'} gap='small' pad={{ horizontal: 'small' }}>
                <StandardText label={"Information"} bold size={'medium'} />
                <Box direction='row' gap='small' style={{ minHeight: 'auto' }}>
                    <FormFieldInput
                        label={'Name'}
                        value={partner.name}
                        onChange={event => onPartnerChange('name', event.target.value)}
                    />
                    <FormFieldInput
                        label={'Address'}
                        value={partner.address}
                        onChange={event => onPartnerChange('address', event.target.value)}
                    />
                </Box>
                <Box direction='row' gap='small'>
                    <FormFieldInput
                        label={'Contact'}
                        value={partner.contact}
                        onChange={event => onPartnerChange('contact', event.target.value)}
                    />
                    <FormFieldInput
                        label={'Email'}
                        value={partner.email}
                        onChange={event => onPartnerChange('email', event.target.value)}
                    />
                </Box>
                <Box direction='row' gap='small'>
                    <FormFieldInput
                        label={'Phone'}
                        value={partner.phone}
                        onChange={event => onPartnerChange('phone', event.target.value)}
                    />
                    <FormFieldInput
                        label={'Hubrise id'}
                        placeholder="zqqmn-xxx"
                        value={partner.hubrise_id}
                        onChange={event => onPartnerChange('hubrise_id', event.target.value)}
                    />
                </Box>
            </Box>
            <Box width={'full'} pad={{ horizontal: 'small' }} gap='small'>
                <StandardText label={"Account"} bold size={'medium'} />
                <Box width={'full'} gap='small' style={{ minHeight: 'auto' }}>
                    <Box direction='row' gap='small' style={{ minHeight: 'auto' }}>
                        <FormFieldInput
                            label={'First name *'}
                            value={partner.account?.firstname}
                            onChange={event => onAccountChange('firstname', event.target.value)}
                        />
                        <FormFieldInput
                            label={'Last name *'}
                            value={partner.account?.lastname}
                            onChange={event => onAccountChange('lastname', event.target.value)}
                        />
                    </Box>
                    <Box direction='row' gap='small' style={{ minHeight: 'auto' }}>
                        <FormFieldInput
                            label={'Email/Username *'}
                            type="email"
                            value={partner.account?.username}
                            onChange={event => onAccountChange('username', event.target.value)}
                        />
                        <FormFieldInput
                            label={'Password'}
                            type='password'
                            value={partner.account?.password}
                            onChange={event => onAccountChange('password', event.target.value)}
                        />
                    </Box>
                </Box>
            </Box>
            <Box width={'full'} pad={{ horizontal: 'small' }} gap='small'>
                <Box justify="between" width={'full'} direction="row" align="center">
                    <StandardText label={"Opening hours"} bold size={'medium'} />
                    <PrimaryButton label="Push to Uber/Deliveroo"
                        icon={<Upload size="small" color="#fff" />}
                        onClick={pushOpening}
                        background={COLOR.green}
                    />
                </Box>
                <Box width={'full'}>
                    {weekdays.map(label => (
                        <MenuTime
                            key={label}
                            label={label}
                            partner={partner}
                            onPartnerChange={onPartnerChange}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default PartnerInfo;