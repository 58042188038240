import { navigate } from "gatsby";
import { Box, Button, Image, List } from "grommet";
import { Add, Catalog, FormEdit, View } from "grommet-icons";
import React from "react";
import PrimaryButton from "../elements/primary_button";
import StandardText from "../elements/standard_text";

const CatalogsInfo = ({ catalogs, onAddCatalog }) => {
    return (
        <Box width={'full'} gap='small' style={{ minHeight: 'auto' }}>
            <Box width={'full'} gap='small' pad={{ horizontal: 'small' }}>
                <Box width={'full'} justify="between" align="center" direction="row">
                    <StandardText label={"Catalogs"} bold size={'medium'} />
                    <Button icon={<Add size="small" />} size='small' label='Add catalog'
                        onClick={onAddCatalog}
                    />
                </Box>
                {catalogs ? <List data={catalogs} pad="small" >
                    {(datum) => (
                        <Box justify="between" align="center" width={'full'} direction='row'>
                            <Box direction="row-responsive" gap="small" align="center"
                                onClick={() => navigate(`/catalogs/${datum.id}/products`)}
                            >
                                <Box width={'xsmall'}>
                                    {datum.deliveroo_menu_banner || datum.ubereat_menu_banner ?
                                        <Image src={datum.deliveroo_menu_banner || datum.ubereat_menu_banner} fit='contain' /> :
                                        <Catalog />
                                    }
                                </Box>
                                <StandardText label={datum.name} style={{ fontWeight: 'bold' }} />
                            </Box>
                            <Box gap="small" direction="row" justify="center" align="center">
                                <Box onClick={() => navigate(`/catalogs/${datum.id}`)}>
                                    <Button icon={<FormEdit />} label='Edit' size="small" />
                                </Box>
                                <PrimaryButton icon={<View size="small" />} label={"Open"} onClick={() => navigate(`/catalogs/${datum.id}/products`)} />
                            </Box>
                        </Box>
                    )}
                </List> : null}
            </Box>
        </Box>
    )
}

export default CatalogsInfo;