import { Box, Button, Select } from "grommet";
import React, { useEffect, useState } from "react";
import { opening_options, weekdays } from "../common/utils";
import StandardText from "../elements/standard_text";

const MenuTime = ({ label, partner, onPartnerChange }) => {

    const [closed, setClosed] = useState(partner.opening_hours.find(c => c.label === label).is_closed)

    useEffect(() => {
        setClosed(partner.opening_hours.find(c => c.label === label).is_closed)
    }, [partner, label]);

    const onTimeChange = (_label, field, value) => {
        partner.opening_hours.find(c => c.label === label).opening.find(c => c.label === _label)[field] = value;
        onPartnerChange('opening_hours', [...partner.opening_hours]);
    }

    const getTime = (_label, field) => {
        const opening = partner.opening_hours.find(c => c.label === label).opening || [];

        const value = opening.find(c => c.label === _label) || {};
        return value[field];
    }

    const changeOpen = () => {
        const val = !closed;
        setClosed(val);
        partner.opening_hours.find(c => c.label === label).is_closed = val;
        if (val) {
            const day = partner.opening_hours.find(c => c.label === label);
            let opening = day.opening;
            if(!opening?.length) {
                opening = [{from: '', to: '', label: 'first'}, {from: '', to: '', label: 'second'}, ];
                day.opening = opening;
            } else {
                opening[0].from = '';
                opening[0].to = '';
                opening[1].from = '';
                opening[1].to = '';
            }
        }
        onPartnerChange('opening_hours', [...partner.opening_hours]);
    }

    const copyToTheFollowing = () => {
        const idx = weekdays.indexOf(label) + 1;
        if (idx >= weekdays.length) return;
        const _nextLabel = weekdays[idx];
        partner.opening_hours.find(c => c.label === _nextLabel).opening[0].from = partner.opening_hours.find(c => c.label === label).opening[0].from;
        partner.opening_hours.find(c => c.label === _nextLabel).opening[0].to = partner.opening_hours.find(c => c.label === label).opening[0].to;
        partner.opening_hours.find(c => c.label === _nextLabel).opening[1].from = partner.opening_hours.find(c => c.label === label).opening[1].from;
        partner.opening_hours.find(c => c.label === _nextLabel).opening[1].to = partner.opening_hours.find(c => c.label === label).opening[1].to;
        onPartnerChange('opening_hours', [...partner.opening_hours]);
    }

    return (
        <Box direction="row" pad={'small'} align='center' gap="small" >
            <Box width={'xsmall'}>
                <StandardText label={label} size='small' />
            </Box>
            <Box width={'xsmall'}>
                <Select options={opening_options}
                    size='xsmall'
                    value={getTime('first', 'from')}
                    placeholder='from'
                    onChange={({ option }) => onTimeChange('first', 'from', option)}
                    disabled={closed}
                />
            </Box>
            <Box width={'xsmall'}>
                <Select options={opening_options}
                    size='xsmall'
                    value={getTime('first', 'to')}
                    placeholder='to'
                    onChange={({ option }) => onTimeChange('first', 'to', option)}
                    disabled={closed}
                />
            </Box>
            <Box width={'xxsmall'} />
            <Box width={'xsmall'}>
                <Select options={opening_options}
                    size='xsmall'
                    value={getTime('second', 'from')}
                    placeholder='from'
                    onChange={({ option }) => onTimeChange('second', 'from', option)}
                    disabled={closed}
                />
            </Box>
            <Box width={'xsmall'}>
                <Select options={opening_options}
                    size='xsmall'
                    value={getTime('second', 'to')}
                    placeholder='to'
                    onChange={({ option }) => onTimeChange('second', 'to', option)}
                    disabled={closed}
                />
            </Box>
            <Button plain size="small"
                onClick={() => changeOpen()}
            >
                <StandardText
                    size="small"
                    label={!closed ? 'Close the day' : 'Open the day'}
                    color='brand'
                    style={{ fontWeight: 'bold' }}
                />
            </Button>
            <Button plain size="small"
                onClick={() => copyToTheFollowing()}
            >
                <StandardText
                    size="small"
                    label={'Copy to the following'}
                    color='brand'
                />
            </Button>
        </Box>
    )
}

export default MenuTime;