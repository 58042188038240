import { navigate } from "gatsby";
import { Box, Layer } from "grommet";
import { Pause, Play, Previous, Save, Test } from "grommet-icons";
import React from "react";
import NewItemPopup from "../../../components/common/new_item";
import { EventEmitter } from "../../../components/elements/event_emitter";
import PrimaryButton from "../../../components/elements/primary_button";
import StandardText from "../../../components/elements/standard_text";
import LoadingAnimation from "../../../components/loading_animation";
import NavigationFrame from "../../../components/nav_frame";
import CatalogsInfo from "../../../components/partners/catalog_info";
import PartnerInfo from "../../../components/partners/info";
import catalogService from "../../../service/catalog_service";
import service from "../../../service/partner_service";
import { hasAdminRole } from "../../../service/storage_service";
import { getIdAfter } from "../../../service/utils";
import History from "../../../components/common/history";
import moment from "moment";
import SalesForceInfo from "../../../components/common/salesforce_info";
import { OPEN, PAUSED } from "../../../service/model_utils";
import XRating from "../../../components/common/rating";

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: undefined,
      savable: false,
      newCatalog: undefined,
      loading: false,
      original: undefined,
    };
  }

  componentDidMount = () => {
    if (!hasAdminRole()) navigate("/404");
    const id = getIdAfter("partners/");

    if (!id) {
      //navigate('/not-found');
      return;
    }
    this.setState({ loading: "Loading partner..." });
    service
      .getPartner(id)
      .then((res) => {
        const partner = res.data.data;
        this.setState({ partner, original: partner, loading: false });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
      });
  };

  onPartnerChange = (field, value) => {
    const partner = this.state.partner;
    partner[field] = value;
    this.setState({ partner: { ...partner }, savable: true });
  };

  save = async () => {
    const account = await this.updatePartnerAccount();
    if (!account) return;
    const partner = { ...this.state.partner };
    partner.account = account.id;
    this.setState({ loading: "Updating partner..." });
    return await service
      .savePartner(partner)
      .then((res) => {
        EventEmitter.dispatch("showMessage", { message: "Saved successfully" });
        const original = this.state.original;
        if (original.name !== partner.name) {
          // update name to all catalogs of this partner
          const catalogs = partner.catalogs || [];
          for (const cat of catalogs) {
            cat.partner_name = partner.name;
            catalogService.saveCatalog(cat);
          }
        }
        this.setState({
          savable: false,
          partner: res.data.data,
          loading: false,
          original: res.data.data,
        });
        return true;
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
        return false;
      });
  };

  updatePartnerAccount = async () => {
    let account = this.state.partner.account;
    if (account && account.username) {
      if (!account.firstname || !account.lastname) {
        EventEmitter.dispatch("showMessage", {
          message: "First name & lastname are requried.",
          messageType: "warning",
        });
        return;
      }
      if (!account.id && !account.password) {
        EventEmitter.dispatch("showMessage", {
          message: "Password is required.",
          messageType: "warning",
        });
        return;
      }
      if (!account.password) delete account.password;
      if (account.password && account.password.length < 6) {
        EventEmitter.dispatch("showMessage", {
          message: "Password is at least 6 characters.",
          messageType: "warning",
        });
        return;
      }
      try {
        const payload = {
          ...account,
          email: account.username,
          confirmed: true,
        };
        this.setState({ loading: "Updating account ..." });
        const res = await service.saveAccount(this.state.partner.id, payload);
        return res.data;
      } catch (error) {
        console.log(error.response.data);
        EventEmitter.dispatch("showMessage", {
          message: error.response?.data?.error?.message || "Unexpected error.",
          messageType: "error",
        });
        this.setState({ loading: false });
        return false;
      }
    }
    return true;
  };

  createItem = (item) => {
    item.partner = this.state.partner.id;
    item.partner_name = this.state.partner.name;
    item.partner_id = this.state.partner.id;
    catalogService
      .saveCatalog(item)
      .then((res) => {
        const partner = this.state.partner;
        partner.catalogs.push(res.data.data);
        this.setState({ partner: { ...partner } });
        EventEmitter.dispatch("showMessage", {
          message: "Catalog is created successfully",
        });
      })
      .catch(EventEmitter.errorHandler);
  };

  switchStatus = () => {
    const partner = this.state.partner;
    const nextStatus = partner.status === PAUSED ? OPEN : PAUSED;
    this.setState({ loading: "Switching status to: " + nextStatus });
    service
      .updateStatus(partner.id, nextStatus)
      .then((res) => {
        this.setState({
          partner: {
            ...res.data.partner,
            account: partner.account,
            catalogs: partner.catalogs,
          },
          loading: false,
        });
        EventEmitter.dispatch("showMessage", { message: res.data.message });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  onAccountChange = (field, value) => {
    const partner = this.state.partner;
    let account = partner.account || {};
    account[field] = value;
    partner.account = account;
    this.setState({ partner: { ...partner }, savable: true });
  };

  pushOpeningHours = async () => {
    if (this.state.savable) {
      const successful = await this.save();
      if (!successful) {
        return;
      }
    }
    this.setState({ loading: "Pushing opening hours to Deliveroo & Uber Eat" });
    service
      .pushOpeningHours(this.state.partner.id)
      .then((res) => {
        const { uber, deliveroo } = res.data;

        const uberError = uber
          .filter((u) => u.status !== 200)
          .map((u) => u.message)
          .join("\n");
        const uberOK = uber
          .filter((u) => u.status === 200)
          .map((u) => u.message)
          .join("\n");
        const deliverooError = deliveroo
          .filter((u) => u.status !== 200)
          .map((u) => u.message)
          .join("\n");
        const deliverooOK = uber
          .filter((u) => u.status === 200)
          .map((u) => u.message)
          .join("\n");

        if (uberOK) {
          EventEmitter.dispatch("showMessage", {
            message: "UBER EATS: " + uberOK,
          });
        }
        if (uberError) {
          EventEmitter.dispatch("showMessage", {
            message: "UBER EATS: " + uberError,
            messageType: "error",
          });
        }
        if (deliverooOK) {
          EventEmitter.dispatch("showMessage", {
            message: "DELIVEROO: " + deliverooOK,
          });
        }
        if (deliverooError) {
          EventEmitter.dispatch("showMessage", {
            message: "DELIVEROO: " + deliverooError,
            messageType: "error",
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  getAutoOpenTime = () => {
    const partner = this.state.partner;
    if (partner.status === PAUSED && partner.next_opened_at) {
      const expired = moment().isAfter(moment(partner.next_opened_at));
      if (expired) return;

      return expired ? "" : moment(partner.next_opened_at).format("HH:mm");
    }
    return "";
  };

  createTestOrder = () => {
    service
      .createTestOrder(this.state.partner.id)
      .then((_) =>
        EventEmitter.dispatch("showMessage", {
          message: "Test order is created",
        })
      )
      .catch(EventEmitter.errorHandler);
  };

  render() {
    return (
      <NavigationFrame>
        {!this.state.partner ? (
          <Box width={"full"} justify="center" align="center">
            <LoadingAnimation text={this.state.loading} />
          </Box>
        ) : (
          <Box width={"full"} pad={"small"}>
            <Box
              gridArea="header"
              direction="row"
              align="center"
              width={"full"}
              background="#F8F8F8"
              justify="between"
              pad={"small"}
              margin={{ bottom: "small" }}
            >
              <Box align="center" gap="small" direction="row">
                <PrimaryButton
                  label={"Back"}
                  onClick={() => {
                    if (typeof window === "undefined") return;
                    window.history.back();
                  }}
                  icon={<Previous size="small" color={"#fff"} />}
                />
              </Box>
              <Box justify="center" align="center">
                <StandardText
                  label={this.state.partner?.name}
                  size="large"
                  bold
                  color="brand"
                />

                <StandardText
                  label={
                    "Last connection: " +
                    moment
                      .utc(this.state.partner?.app_connected_at)
                      .local()
                      .startOf("seconds")
                      .fromNow()
                  }
                  size="small"
                  color="brand"
                />
              </Box>

              <Box
                direction="row"
                align="center"
                gap="small"
                pad={{ horizontal: "medium" }}
              >
                <Box align="center">
                  <PrimaryButton
                    label="Test order"
                    icon={<Test size="small" color="#fff" />}
                    onClick={this.createTestOrder}
                  />
                </Box>
                <PrimaryButton
                  background={
                    this.state.partner.status === PAUSED ? "#6DB24F" : "#E92C70"
                  }
                  onClick={this.switchStatus}
                  icon={
                    this.state.partner.status === PAUSED ? (
                      <Play size="small" color="#fff" />
                    ) : (
                      <Pause size="small" color="#fff" />
                    )
                  }
                  label={
                    this.getAutoOpenTime()
                      ? `Opened auto at ${this.getAutoOpenTime()}`
                      : this.state.partner.status === PAUSED
                        ? "Open (manually)"
                        : "Pause"
                  }
                />
                <Box align="center">
                  <PrimaryButton
                    label="Save"
                    disabled={!this.state.savable}
                    icon={<Save size="small" color="#fff" />}
                    onClick={this.save}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              width={"xlarge"}
              pad={{ vertical: "small", top: "small", bottom: "large" }}
              style={{ overflowY: "auto" }}
              gap="medium"
            >
              <SalesForceInfo
                label={"Account Id"}
                object_id={this.state.partner.salesforce_account_id}
                onChange={(val) =>
                  this.onPartnerChange("salesforce_account_id", val)
                }
              />
              <PartnerInfo
                partner={this.state.partner}
                onAccountChange={this.onAccountChange}
                onPartnerChange={this.onPartnerChange}
                pushOpening={this.pushOpeningHours}
              />

              <CatalogsInfo
                catalogs={this.state.partner?.catalogs}
                onAddCatalog={() => this.setState({ newCatalog: true })}
              />

              <Box style={{ maxHeight: 700 }}>
                <History
                  resource_type={"partner"}
                  resource_id={this.state.partner.id}
                  filter={{
                    label: "Fetch orders excluded",
                    value: "Fetch orders",
                  }}
                />
              </Box>

              <Box style={{ maxHeight: 700 }}>
                <Box
                  width={"xlarge"}
                  justify="center"
                  style={{ minHeight: "auto" }}
                  pad="small"
                >
                  <Box pad="small">
                    <StandardText
                      label={"Avis"}
                      size="medium"
                      style={{ fontWeight: "bold" }}
                    />
                  </Box>
                  <Box width={"full"} style={{ maxHeight: 700, minHeight: 'auto', overflow: 'auto' }}>
                    <XRating
                      partner_id={this.state.partner?.id}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {this.state.loading ? (
              <Layer>
                <Box
                  justify="center"
                  align="center"
                  style={{ width: 300, height: 300 }}
                  pad={"large"}
                >
                  <LoadingAnimation text={this.state.loading} />
                </Box>
              </Layer>
            ) : null}
          </Box>
        )}

        {this.state.newCatalog ? (
          <NewItemPopup
            label={"Catalog"}
            onCreate={this.createItem}
            onClose={() => this.setState({ newCatalog: undefined })}
          />
        ) : null}
      </NavigationFrame>
    );
  }
}

export default Partner;
